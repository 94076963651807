import React from 'react';
import { ArrowRight, Zap, Shield, Globe } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { Button } from './ui/Button';
import { Link } from 'react-router-dom';
import {Phone } from 'lucide-react';

const WhatsAppIcon = ({ className }) => (
  <svg 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
  </svg>
);

const FeatureCard = ({ icon, title, description }) => (
  <Card>
    <CardHeader>
      <CardTitle className="flex items-center text-xl font-semibold">
        {icon}
        <span className="ml-2">{title}</span>
      </CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-gray-600">{description}</p>
    </CardContent>
  </Card>
);

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navigation */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <span className="text-2xl font-bold text-blue-600">Careerlets</span>
            </div>
            
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-blue-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl">
            Transfer Money with Ease
          </h1>
          <p className="mt-6 text-xl max-w-3xl">
            Careerlets provides a fast, secure, and convenient way to make Paypal to Mobile Money transactions. Join thousands of satisfied users today!
          </p>
          <div className="mt-10">
            <Link to="/careerlets">
              <Button size="lg" className="bg-white text-blue-600 hover:bg-gray-100">
                Get Started <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </Link>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
          Why Choose Careerlets?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <FeatureCard
            icon={<Zap className="h-6 w-6 text-yellow-500" />}
            title="Lightning Fast"
            description="Send money in seconds, not days. Our cutting-edge technology ensures your transfers are processed instantly."
          />
          <FeatureCard
            icon={<Shield className="h-6 w-6 text-green-500" />}
            title="Secure Transfers"
            description="Your security is our top priority. We use state-of-the-art encryption to protect your data and transactions."
          />
          <FeatureCard
            icon={<Globe className="h-6 w-6 text-blue-500" />}
            title="Africa Global Reach"
            description="Send money from anywhere to Africa. Connect with your loved ones, no matter where they are."
          />
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 mb-4">
            Ready to get started?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Join Careerlets today and experience the future of money transfers.
          </p>
          <Link to="/careerlets">
            <Button size="lg" className="bg-blue-600 text-white hover:bg-blue-700">
              Make a Transfer
            </Button>
          </Link>
        </div>
      </div>
      <footer className="bg-gray-800 text-white mt-8 py-6">
  <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <h3>
          <Link 
            to="/converter" 
            className="text-base font-medium mb-3"
          >
            About Us
          </Link>
        </h3>
        <p className="text-sm text-gray-300">Careerlets is a leading platform for secure and fast PayPal to Mobile Money transfers across Africa.</p>
      </div>
      
      <div>
        <h3 className="text-base font-medium mb-3">Quick Links</h3>
        <ul className="text-sm space-y-1">
          <li><Link to="/" className="hover:text-blue-400">Home</Link></li>
          <li><Link to="/careerlets" className="hover:text-blue-400">Send Money </Link></li>
        </ul>
      </div>
      
      <div>
        <h3 className="text-base font-medium mb-3">Contact Us</h3>
        <div className="space-y-2">
          
          <a 
            href="tel:+254701632591"
            className="flex items-center gap-2 text-sm text-gray-300 hover:text-gray-100 transition-colors group"
          >
            <Phone className="w-4 h-4 group-hover:scale-110 transition-transform" />
            <span>+44 7887579367</span>
          </a>
          
          <a 
            href="https://wa.me/254701632591"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-300 hover:text-gray-100 transition-colors group"
          >
            <WhatsAppIcon className="w-4 h-4 group-hover:scale-110 transition-transform" />
            <span>+44 7887579367</span>
          </a>
        </div>
      </div>
    </div>
    
    <div className="mt-6 pt-6 border-t border-gray-700 text-center">
      <p className="text-xs text-gray-400">&copy; 2024 Careerlets. All rights reserved.</p>
    </div>
  </div>
</footer>
    </div>
    
  );
};

export default HomePage;