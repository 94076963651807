import React, { useState } from 'react';

const currencyData = [
  { code: 'KSH', name: 'Kenyan Shilling', rate: 1, flag: '🇰🇪' },
  { code: 'UGX', name: 'Ugandan Shilling', rate: 27.154, flag: '🇺🇬' },
  { code: 'TZS', name: 'Tanzanian Shilling', rate: 20.287, flag: '🇹🇿' },
  { code: 'RWF', name: 'Rwandan Franc', rate: 9.951, flag: '🇷🇼' },
  { code: 'ZMK', name: 'Zambian Kwacha', rate: 0.196, flag: '🇿🇲' },
  { code: 'BIF', name: 'Burundi Franc', rate: 21.579, flag: '🇧🇮' },
];

const CurrencyConverter = () => {
  const [amount, setAmount] = useState('1');
  const [fromCurrency, setFromCurrency] = useState('KSH');
  const [toCurrency, setToCurrency] = useState('UGX');

  const convert = (amount, from, to) => {
    const fromRate = currencyData.find(c => c.code === from).rate;
    const toRate = currencyData.find(c => c.code === to).rate;
    return ((amount * toRate) / fromRate).toFixed(3);
  };

  const formatNumber = (value) => {
    if (!value) return '0';
    return new Intl.NumberFormat().format(parseFloat(value));
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const convertedAmount = amount ? convert(parseFloat(amount.replace(/,/g, '')), fromCurrency, toCurrency) : '0';
  const displayAmount = formatNumber(amount);
  const displayConvertedAmount = formatNumber(convertedAmount);

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center mb-6">
        East African Currency Converter
      </h1>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-2" htmlFor="amount">
          Amount
        </label>
        <input
          id="amount"
          type="text"
          value={displayAmount}
          onChange={handleAmountChange}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter amount"
        />
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium mb-2">
            From
          </label>
          <select
            value={fromCurrency}
            onChange={(e) => setFromCurrency(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {currencyData.map(currency => (
              <option key={currency.code} value={currency.code}>
                {currency.flag} {currency.code}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            To
          </label>
          <select
            value={toCurrency}
            onChange={(e) => setToCurrency(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {currencyData.map(currency => (
              <option key={currency.code} value={currency.code}>
                {currency.flag} {currency.code}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-lg">
        <div className="text-center">
          <p className="text-sm text-gray-600">
            {displayAmount} {fromCurrency} =
          </p>
          <p className="text-2xl font-bold mt-1">
            {displayConvertedAmount} {toCurrency}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrencyConverter;