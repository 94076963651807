import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import Careerlets from './components/Careerlets';
import Converter from './components/CurrencyConverter'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/careerlets" element={<Careerlets />} />
          <Route path="/converter" element={<Converter />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
