import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountryDropdown } from 'react-country-region-selector';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';
import emailjs from 'emailjs-com'; // Import EmailJS
import paypal from '../paypal.png';
import mtn from '../mtn.png';
import airtel from '../airtel.png';
import mpesa from '../mpesa.png';
import vodacom from '../vodacom.png';
import tigo from '../tigo.png';
import {Phone } from 'lucide-react';

const WhatsAppIcon = ({ className }) => (
  <svg 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/>
  </svg>
);

const currencyData = [
  { code: 'UGX', name: 'Ugandan Shilling', rate: 3600, flag: '🇺🇬' },
  { code: 'TZS', name: 'Tanzanian Shilling', rate: 2500, flag: '🇹🇿' },
  { code: 'RWF', name: 'Rwandan Franc', rate: 1200, flag: '🇷🇼' },
  { code: 'ZMK', name: 'Zambian Kwacha', rate: 24, flag: '🇿🇲' }, 
  { code: 'BIF', name: 'Burundi Franc', rate: 2600, flag: '🇧🇮' },
];

const formatNumber = (num) => {
  return new Intl.NumberFormat().format(num);
};

const CurrencyConverter = ({ updateParentState }) => {
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState('');
  const [paypalFee, setPaypalFee] = useState('');
  const [transactionFee, setTransactionFee] = useState('');
  const [amountToReceive, setAmountToReceive] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (amount && !selectedCurrency) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  
    if (amount && selectedCurrency) {
      const converted = parseFloat(amount) * selectedCurrency.rate;
      const paypalFeeAmount = (converted * 0.06) + (2 * selectedCurrency.rate);
      const transactionFeeAmount = converted * 0.05; 
      const totalFeesAmount = paypalFeeAmount + transactionFeeAmount;
      const receiveAmount = converted - totalFeesAmount;
  
      setConvertedAmount(converted.toFixed(2));
      setPaypalFee(paypalFeeAmount.toFixed(2));
      setTransactionFee(transactionFeeAmount.toFixed(2));
      setAmountToReceive(receiveAmount.toFixed(2));

      // Update parent state
      updateParentState({
        usdAmount: amount,
        localCurrency: selectedCurrency ? selectedCurrency.code : '',
        convertedAmount: converted.toFixed(2),
        paypalFee: paypalFeeAmount.toFixed(2),
        transactionFee: transactionFeeAmount.toFixed(2),
        amountToReceive: receiveAmount.toFixed(2)
      });
    } else {
      setConvertedAmount('');
      setPaypalFee('');
      setTransactionFee('');
      setAmountToReceive('');

      // Reset parent state
      updateParentState({
        usdAmount: '',
        localCurrency: '',
        convertedAmount: '',
        paypalFee: '',
        transactionFee: '',
        amountToReceive: ''
      });
    }
  }, [amount, selectedCurrency, updateParentState]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm w-full max-w-md mx-auto my-4">
      <h2 className="text-xl font-semibold text-center mb-4 text-blue-600">
        Convert Amount to Send 
      </h2>
      <h2 className="text-m font-semibold text-center mb-4 text-green-600">
        The currency entered will automatically be converted to EUROS
      </h2>
      <div className="space-y-3">
        <div className="space-y-1">
          <label htmlFor="usd-amount" className="text-xs font-medium text-gray-600">
            Amount in EUR (min. €25, max. €500)
          </label>
          <input
            id="usd-amount"
            type="number"
            placeholder="Enter amount in EUR"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
        
        <div className="text-red-600 text-xs flex items-center space-x-1">
          <span>⚠️</span>
          <span>Please refresh the page to get current rates.</span>
        </div>

        <div className="space-y-1">
          <label htmlFor="local-currency" className="text-xs font-medium text-gray-600">
            Select Local Currency
          </label>
          <select
            id="local-currency"
            onChange={(e) => setSelectedCurrency(currencyData.find(c => c.code === e.target.value))}
            className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            <option value="">Select local currency</option>
            {currencyData.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.flag} {currency.name}
              </option>
            ))}
          </select>
        </div>

        {showPrompt && (
          <div className="bg-blue-50 text-blue-600 p-3 rounded text-xs">
            Please select a local currency to see the converted amount.
          </div>
        )}

        {selectedCurrency && convertedAmount && (
          <div className="bg-gray-50 p-3 rounded">
            <div className="space-y-1 text-sm">
              <p><span className="font-light">Amount in EUR:</span> {formatNumber(parseFloat(amount))} EUR</p>
              <p>
                <span className="font-light">Exchange Rate:</span> 1 EUR = 
                <span className="text-green-600"> {formatNumber(selectedCurrency.rate)} {selectedCurrency.code}</span>
              </p>
              
             <p>
                <span className="font-light">PayPal Fee:</span> 
                <span className="text-red-600"> {formatNumber(parseFloat(paypalFee))} {selectedCurrency.code}</span>
              </p>
              <p>
                <span className="font-light">Transaction Fee:</span> 
                <span className="text-red-600"> {formatNumber(parseFloat(transactionFee))} {selectedCurrency.code}</span>
              </p>
              
              <p>
                <span className="font-bold">Total Amount to be Received:</span> 
                <span className="text-green-700 font-bold"> {formatNumber(parseFloat(amountToReceive))} {selectedCurrency.code}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Careerlets = () => {
  const [formData, setFormData] = useState({
    paypalName: '',
    paypalEmail: '',
    receiverCountry: '',
    mobileMoneyNetwork: '',
    receiverFullName: '',
    mobileMoneyNumber: '',
    receiverEmail: '',
    amount: '',
    usdAmount: '',
    localCurrency: '',
    convertedAmount: '',
    paypalFee: '',
    transactionFee: '',
    amountToReceive: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const lettersOnly = /^[A-Za-z\s]*$/;
    const numbersOnly = /^\+\d*$/;

    if (name === "receiverFullName" || name === "mobileMoneyNetwork") {
      if (!lettersOnly.test(value)) return;
    }
    if (name === "mobileMoneyNumber") {
      if (!numbersOnly.test(value)) return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountryChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      receiverCountry: val,
    }));
  };

  const isWithinOperatingHours = () => {
    const now = new Date();
    const hours = now.getUTCHours() + 3;
    return hours >= 9 && hours < 23;
  };

  const updateCurrencyData = (data) => {
    setFormData(prevState => ({
      ...prevState,
      ...data,
      amount: data.usdAmount 
    }));
  };

  const sendEmail = (formData) => {
    const templateParams = {
      sender_name: formData.paypalName,
      sender_email: formData.paypalEmail,
      receiver_name: formData.receiverFullName,
      receiver_country: formData.receiverCountry,
      mobile_money_network: formData.mobileMoneyNetwork,
      mobile_money_number: formData.mobileMoneyNumber,
      receiver_email: formData.receiverEmail,
      amount: formData.amount,
      usd_amount: formData.usdAmount,
      local_currency: formData.localCurrency,
      converted_amount: formData.convertedAmount,
      paypal_fee: formData.paypalFee,
      transaction_fee: formData.transactionFee,
      amount_to_receive: formData.amountToReceive,
    };

    // Send to careerlets1@gmail.com
    emailjs.send(
      'service_20z0mcj', // replace with your EmailJS service_id
      'template_fv2xh3l', // replace with your EmailJS template_id
      { ...templateParams, to_email: 'careerlets1@gmail.com' },
      'ok-uYGGjQQJNke36G' // replace with your EmailJS user_id
    ).then(() => {
      // Send to client's PayPal email
      emailjs.send(
        'service_20z0mcj', 
        'template_fv2xh3l',
        { ...templateParams, to_email: formData.paypalEmail },
        'ok-uYGGjQQJNke36G'
      );
    }).catch((error) => {
      console.error('Email error:', error);
      toast.error('An error occurred while sending the email.');
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!isWithinOperatingHours()) {
      toast.error('Sorry, transfers are made between 9:00am and 11:00pm East African Time.');
      setIsSubmitting(false);
      return;
    }

    const allFieldsFilled = Object.values(formData).every((field) => field !== '');
    if (!allFieldsFilled) {
      toast.error('Please fill in all fields before submitting.');
      setIsSubmitting(false);
      return;
    }

    const amount = parseFloat(formData.amount);
    if (isNaN(amount) || amount < 25 || amount > 500) {
      toast.error('The amount must be between €25 and €500.');
      setIsSubmitting(false);
      return;
    }
    

    try {
      sendEmail(formData); // Send emails to your email and the client's PayPal email

      toast.success('Proceed to Payments to finish the transaction.');
      setSubmitted(true);
      setFormData({
        paypalName: '',
        paypalEmail: '',
        receiverCountry: '',
        mobileMoneyNetwork: '',
        receiverFullName: '',
        mobileMoneyNumber: '',
        receiverEmail: '',
        amount: '',
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <header className="bg-blue-600 p-3">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <Link to="/" className="text-white flex items-center text-sm">
            <Home className="mr-1 w-4 h-4" />
            Home
          </Link>
          <h1 className="text-white text-xl font-semibold">Careerlets</h1>
        </div>
      </header>

      <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg my-8">
        <ToastContainer />
        <div>
          <form onSubmit={onSubmit} className="flex flex-col gap-4">
            <div className="text-center mb-1">
              <img src={paypal} alt="PayPal" className="h-16 w-auto mx-auto mb-2" />
            </div>
            <div className="flex justify-center gap-2 mb-4">
              <img src={mtn} alt="MTN" className="h-8 w-auto" />
              <img src={airtel} alt="Airtel" className="h-8 w-auto" />
              <img src={mpesa} alt="M-Pesa" className="h-8 w-auto" />
              <img src={vodacom} alt="Vodacom" className="h-8 w-auto" />
              <img src={tigo} alt="Tigo" className="h-8 w-auto" />
            </div>

            <div className="mb-6">
            <CurrencyConverter updateParentState={updateCurrencyData} />
            </div>

            <h3 className="text-base font-semibold text-center mb-3">Sender's Information</h3>

            <div>
              <label htmlFor="paypalName" className="block text-xs font-medium mb-1 text-gray-700">Sender's PayPal Name</label>
              <input
                type="text"
                id="paypalName"
                name="paypalName"
                value={formData.paypalName}
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <div>
              <label htmlFor="paypalEmail" className="block text-xs font-medium mb-1 text-gray-700">Sender's PayPal Email</label>
              <input
                type="email"
                id="paypalEmail"
                name="paypalEmail"
                value={formData.paypalEmail}
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <div>
              <label htmlFor="amount" className="block text-xs font-medium mb-1 text-gray-700">Amount to be Sent (€)</label>
              <input
                type="text"
                id="amount"
                name="amount"
                value={formData.amount}
                placeholder="min. €25    max. €500"
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <h3 className="text-base font-semibold text-center mb-3 mt-4">Receiver's Information</h3>

            <div>
              <label htmlFor="receiverCountry" className="block text-xs font-medium mb-1 text-gray-700">Receiver's Country</label>
              <CountryDropdown
                value={formData.receiverCountry}
                onChange={handleCountryChange}
                priorityOptions={['UG', 'TZ', 'RW', 'BI', 'ZM']}
                whitelist={['UG', 'TZ', 'RW', 'BI', 'ZM']}
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="mobileMoneyNetwork" className="block text-s font-medium mb-1 text-gray-700">Mobile Money Network <span className="text-red-500 text-xs">(For Zambia, only MTN transactions are allowed at the moment)</span></label>
              <input
                type="text"
                id="mobileMoneyNetwork"
                name="mobileMoneyNetwork"
                value={formData.mobileMoneyNetwork}
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <div>
              <label htmlFor="receiverFullName" className="block text-xs font-medium mb-1 text-gray-700">Receiver's Full Name</label>
              <input
                type="text"
                id="receiverFullName"
                name="receiverFullName"
                value={formData.receiverFullName}
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <div>
              <label htmlFor="mobileMoneyNumber" className="block text-xs font-medium mb-1 text-gray-700">
                Mobile Money Number <span className="text-red-500 text-xs">(MUST include code: +256, +255, etc.)</span>
              </label>
              <input
                type="tel"
                id="mobileMoneyNumber"
                name="mobileMoneyNumber"
                value={formData.mobileMoneyNumber}
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <div>
              <label htmlFor="receiverEmail" className="block text-xs font-medium mb-1 text-gray-700">Receiver's Email Address</label>
              <input
                type="email"
                id="receiverEmail"
                name="receiverEmail"
                value={formData.receiverEmail}
                onChange={handleChange}
                required
                className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-sm"
              />
            </div>

            <button
              type="submit"
              className={`mt-4 p-2 rounded-md text-white font-medium text-sm ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Processing...' : 'Submit'}
            </button>
          </form>
          {submitted && (
            <div className="mt-4 text-center">
              <a
                href
                rel="noreferrer"
                className="inline-block px-4 py-2 text-sm text-white bg-blue-500 rounded-md font-medium hover:bg-blue-600 transition duration-300"
              >
                Proceed to Payments
              </a>
            </div>
          )}

          <p className="text-center text-red-500 text-xs mt-4">
            Warning: Transfers are made between 9:00am and 11:00pm EAT.
          </p>
        </div>
      </div>

      <footer className="bg-gray-800 text-white mt-8 py-6">
  <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
      <h3>
          <Link 
            to="/converter" 
            className="text-base font-medium mb-3"
          >
            About Us
          </Link>
        </h3>
        <p className="text-sm text-gray-300">Careerlets is a leading platform for secure and fast PayPal to Mobile Money transfers across Africa.</p>
      </div>
      
      <div>
        <h3 className="text-base font-medium mb-3">Quick Links</h3>
        <ul className="text-sm space-y-1">
        <li><Link to="/" className="hover:text-blue-400">Home</Link></li>
          <li><Link to="/careerlets" className="hover:text-blue-400">Send Money </Link></li>
        </ul>
      </div>
      
      <div>
        <h3 className="text-base font-medium mb-3">Contact Us</h3>
        <div className="space-y-2">
          
          <a 
            href="tel:+254701632591"
            className="flex items-center gap-2 text-sm text-gray-300 hover:text-gray-100 transition-colors group"
          >
            <Phone className="w-4 h-4 group-hover:scale-110 transition-transform" />
            <span>+44 7887579367</span>
          </a>
          
          <a 
            href="https://wa.me/254701632591"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-300 hover:text-gray-100 transition-colors group"
          >
            <WhatsAppIcon className="w-4 h-4 group-hover:scale-110 transition-transform" />
            <span>+44 7887579367</span>
          </a>
        </div>
      </div>
    </div>
    
    <div className="mt-6 pt-6 border-t border-gray-700 text-center">
      <p className="text-xs text-gray-400">&copy; 2024 Careerlets. All rights reserved.</p>
    </div>
  </div>
</footer>
    </>
  );
};

export default Careerlets;